<template>
    <Error errorIcon="$serverErrorIcon" errorText="Something Went Wrong!" />
</template>

<script>
import { mapGetters } from "vuex";
import { TRIGGER_NOTIFICATION } from "@/store/actions.type";

export default {
    name: "not_ready",

    metaInfo() {
        return this.meta(this.$options.name, window.location.href);
    },

    components: {
        Error: () => import("@/components/Error").then(m => m.default)
    },

    mounted() {
        this.$store.dispatch(TRIGGER_NOTIFICATION, { message: "Something Went Wrong!", type: "error", timeout: 3000 });
    },

    computed: {
        ...mapGetters(["meta"])
    },

    data() {
        return {};
    }
};
</script>

<style lang="scss">
</style>